import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import { get } from "lodash";
import { Link } from "react-router-dom";
import { ACTION_CONST, ROUTES, STATUS } from "../../shared/constants";
import { BSC_EXPLORER, STAKING_CONTRACT_ADDRESS, MODE , BSC_CWT_ADDRESS} from "../../_configs";
import { Button } from "react-bootstrap";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../shared/utils";

// import AOS from 'aos';
// import 'aos/dist/aos.css';

const StakingTab = (props) => {
    const dispatch = useDispatch();
    const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
    const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));
    const walletAddress = useSelector((state) => get(state, "utils.walletAddress", ""));
    const stakingInfo = useSelector((state) => get(state, "wallet.stakingInfo", {}));
    const stakingWalletInfo = useSelector((state) => get(state, "wallet.stakingWalletInfo", {}));
    const bnbBalance = useSelector((state) => get(state, "wallet.bnbBalance", 0));
    const bscPadBalance = useSelector((state) => get(state, "wallet.bscpadBalance", 0));
    const [acceptTerm, setAcceptTerm] = useState(false);

    const [swapAmount, setSwapAmount] = useState('0')
    const [isMaxAmount, setIsMaxAmount] = useState(false);

    const [swapCurrentStep, setSwapCurrentStep] = useState(1);
    const [enableSwapBtn, setEnableSwapBtn] = useState(false);

    const [transactionHash, setTransactionHash] = useState("")
    const [isSubmitOK, setIsSubmitOK] = useState(false);



    useEffect(() => {
        if (swapCurrentStep === 1) {
            if (acceptTerm &&
                isConnectWallet &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(bscPadBalance) > 0 &&
                bnbBalance > 0 &&
                stakingWalletInfo["withdrawTimestamp"] === 0
            ) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }, [acceptTerm, isConnectWallet, bnbBalance, bscPadBalance, stakingInfo, stakingWalletInfo, swapCurrentStep])


    useEffect(() => {

        setSwapStepActive();

    }, [swapCurrentStep])

    const swapBack = () => {
        if (swapCurrentStep === 1) {
            return;
        } else {
            setSwapCurrentStep(swapCurrentStep - 1);
        }
    }

    const swapNext = async () => {
        if (swapCurrentStep === 5) return;

        if (swapCurrentStep === 1 && parseFloat(swapAmount) === 0) {
            setEnableSwapBtn(false);
            setSwapCurrentStep(swapCurrentStep + 1);
            return;
        }

        if (swapCurrentStep === 3) {
            // click approve
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                });
                let amount = isMaxAmount ? bscPadBalance : swapAmount
                //check allowance 
                const allowance = await walletUtils.getAllowance(BSC_CWT_ADDRESS[MODE], STAKING_CONTRACT_ADDRESS[MODE]);
             
                if (BigNumber(allowance).gte(BigNumber(amount))) {
                    setSwapCurrentStep(4);
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });
                    return;
                }

                // debugger
                walletUtils.approve({ tokenContractAddress: stakingInfo["tokenAddr"], contractAddress: STAKING_CONTRACT_ADDRESS[MODE], amount: amount }, (data) => {
                    //function success
                    if (data.status === STATUS.APPROVED) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        });
                        dispatch({
                            type: ACTION_CONST.ALERT_SUCCESS,
                            message: "Approve Tokens successfully!"
                        });
                        setSwapCurrentStep(4);
                    }
                    //function fails
                    if (data.status === STATUS.APPROVE_FAILS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: "Failed to Approve Tokens!"
                        })
                    }
                })
            }
        } else if (swapCurrentStep === 4) {
            // click deposit
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingDeposit({ amount: isMaxAmount ? bscPadBalance : swapAmount }, (result) => {
                    if (result.status === STATUS.STAKING_DEPOSIT_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true)
                        setTransactionHash(result.txID)

                        //change to result screen
                        setSwapCurrentStep(5);

                    }
                    if (result.status === STATUS.STAKING_DEPOSIT_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                                message:"Deposit stake fail!"
                        })
                    }
                })
            }
        } else {
            setSwapCurrentStep(swapCurrentStep + 1);
        }
    }


    //handle input swap change
    const handleInputSwap = (e) => {
        setIsMaxAmount(false);

        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setSwapAmount(e.target.value);

            //check input
            if (BigNumber(e.target.value).lte(BigNumber(bscPadBalance)) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }

    const submitDone = () => {
        setSwapAmount('0');
        setIsMaxAmount(true);
        setSwapCurrentStep(1);
        setIsSubmitOK(false);
    }

    const handleMaxButtonClick = () => {
        setSwapAmount(helpers.formatNumberDownRound(bscPadBalance, 4));
        setEnableSwapBtn(true);
        setIsMaxAmount(true);
    }

    const setSwapStepActive = ()=> {
        $('#stake .bs-stepper-header .step').removeClass('active');
        $('#stake .bs-stepper-header .line').removeClass('active');
        for (var i = 1; i <= 5; i++) {
            if (i <= swapCurrentStep) {
                $('#stake #swapButtonStep' + i).addClass('active');
                $('#stake #swapLineStep' + (i - 1)).addClass('active');
            }
        }
        $('#stake .bs-stepper-content').hide();
        $('#stake #SwapStep' + swapCurrentStep).show();
    }



    return (



        <div className="tab-pane fade show active" id="stake" role="tabpanel" aria-labelledby="stake-tab">
            <div className="row justify-content-center mb-4">
                <div className="col-lg-12">
                    <div className="bs-stepper w-100">
                        <div className="bs-stepper-header" role="tablist">
                            {/* your steps here */}
                            <div className="step active" id="swapButtonStep1">
                                <button type="button" className="step-trigger ps-0">
                                    <span className="bs-stepper-circle ms-0"><i className="mdi mdi-format-list-checkbox" /></span>
                                    <span className="bs-stepper-label">Checkpoints</span>
                                </button>
                            </div>
                            <div className="line" id="swapLineStep1" />
                            <div className="step" id="swapButtonStep2">
                                <button type="button" className="step-trigger">
                                    <span className="bs-stepper-circle"><i className="mdi mdi-currency-usd" /></span>
                                    <span className="bs-stepper-label">Amount to Stake</span>
                                </button>
                            </div>
                            <div className="line" id="swapLineStep2" />
                            <div className="step" id="swapButtonStep3">
                                <button type="button" className="step-trigger">
                                    <span className="bs-stepper-circle"><i className="mdi mdi-account-check-outline" /></span>
                                    <span className="bs-stepper-label">Pre-authorization</span>
                                </button>
                            </div>
                            <div className="line" id="swapLineStep3" />
                            <div className="step" id="swapButtonStep4">
                                <button type="button" className="step-trigger">
                                    <span className="bs-stepper-circle"><i className="mdi mdi-shield-account-outline" /></span>
                                    <span className="bs-stepper-label">Confirm</span>
                                </button>
                            </div>
                            <div className="line" id="swapLineStep4" />
                            <div className="step" id="swapButtonStep5">
                                <button type="button" className="step-trigger">
                                    <span className="bs-stepper-circle"><i className="mdi mdi-check" /></span>
                                    <span className="bs-stepper-label">Confirmation</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <div className="">
                        <div className="p-sidebar-card-body">
                            <div className="bs-stepper-content" id="SwapStep1">
                                <div className="text-center">
                                    <h4 className="mb-2 pb-1">Checkpoints</h4>
                                    <p>The following conditions must be met to proceed:</p>
                                </div>
                                <div className="row mt-4 pt-3 d-flex justify-content-center">
                                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                                        <div className={isConnectWallet ? "p-select-card mb-4 selected" : "p-select-card mb-4"}
                                        // onClick={() => { $(this).toggleClass('selected') }}
                                        >
                                            <div className="p-select-card-title"><b>Connected with MetaMask</b></div>
                                            <div className="p-select-card-description">If not connected, click the "Connect Wallet" button in the top right corner</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                                        <div className={parseFloat(bscPadBalance) > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}
                                        // onClick={() => { $(this).toggleClass('selected') }}
                                        >
                                            <div className="p-select-card-title"><b> CWT available to deposit</b></div>
                                            <div className="p-select-card-description">Current Balance: {helpers.formatNumberDownRoundWithExtractMax(bscPadBalance, 4)}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                                        <div className={bnbBalance > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}
                                        // onClick={() => { $(this).toggleClass('selected') }}
                                        >
                                            <div className="p-select-card-title"><b>BNB available in wallet</b></div>
                                            <div className="p-select-card-description">
                                                BNB is required to pay transaction fees on the Binance Smart Chain network.<br />
                                                BNB Balance: {helpers.formatNumberDownRoundWithExtractMax(bnbBalance, 4)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                                        <div className={stakingWalletInfo["withdrawTimestamp"] === 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                            <div className="p-select-card-title"><b>Eligible to stake</b></div>
                                            <div className="p-select-card-description">You cannot stake if you have an active CWT unstake/withdrawal request</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 text-center">
                                    <div className="form-check">
                                        <input className="form-check-input float-none me-1"
                                            type="checkbox" defaultValue id="flexCheckDefault"

                                            onChange={() => setAcceptTerm(!acceptTerm)} />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            I have read the&nbsp;
                                            <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms and Conditions </Link>
                                            {/* <a href="#">Terms and Conditions</a> */}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="bs-stepper-content" id="SwapStep2">
                                <div className="text-center">
                                    <h4 className="mb-2 pb-1">Please enter the amount of CWT you want to stake</h4>
                                    <div className="mx-auto text-start mt-5" style={{ maxWidth: '370px' }}>
                                        <div className="p-form-group mb-1">
                                            <label className="form-label p-main-text">Amount</label>
                                            <div className="p-input-group">
                                                <input type="number" className="form-control px-0" placeholder={0.0} value={swapAmount} onChange={(e) => handleInputSwap(e)} />
                                                <Button
                                                    onClick={handleMaxButtonClick}
                                                >
                                                    MAX
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-start justify-content-between">
                                            <div className="font-14">Balance: <b className="text-warning">{helpers.formatNumberDownRound(bscPadBalance,4)}</b></div>
                                            <div />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bs-stepper-content" id="SwapStep3">
                                <div className="text-center">
                                    <h4 className="mb-2 pb-1">Pre-authorization</h4>
                                    <p>Required transaction 1 of 2</p>
                                    <p>In this step, you grant access to the staking smart contract to accept your CWT</p>
                                </div>
                                <div className="text-center mt-4">
                                    <div className="p-progress-waiting">
                                        <div className="p-progress-percent" style={{ width: '60%' }} />
                                    </div>
                                    <h4 className="mb-2 pb-1">Waiting for the transaction to complete</h4>
                                    <p>Please wait for the transaction to confirm before proceeding.</p>
                                    <p><a className="p-address" href={`${BSC_EXPLORER[MODE]}/address/${walletAddress}`} target="blank">{walletAddress}</a></p>
                                </div>
                            </div>
                            <div className="bs-stepper-content" id="SwapStep4">
                                <div className="text-center">
                                    <h4 className="mb-2 pb-1">Confirm</h4>
                                    <p>Required transaction 2 of 2</p>
                                    <p>
                                        In this step, you deposit the tokens into the staking contract.<br />
                                        After this step, your tokens will be successfully staked.
                                    </p>
                                </div>
                            </div>
                            <div className="bs-stepper-content" id="SwapStep5">
                                <div className="text-center">
                                    <div className="text-warning">
                                        <i className="mdi mdi-check" style={{ fontSize: '50px' }} />
                                    </div>
                                    <h4>Success</h4>
                                    <p>
                                        Congratulations! Your tokens are now staked.<br />
                                        If desired, you may check Binance Smart Chain to confirm the transaction.
                                    </p>
                                    <p><a className="p-address" href={`${BSC_EXPLORER[MODE]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3 mt-4 text-center step-buttons">
                {
                    !isSubmitOK ?
                        <>                <button onClick={() => swapBack()} type="button" disabled={!enableSwapBtn || swapCurrentStep === 1}
                            className="btn btn-primary btn-round btn-back-step me-3 px-5"><i className="mdi mdi-arrow-left me-2" />Previous</button>
                            <button onClick={() => swapNext()} type="button" disabled={!enableSwapBtn || swapCurrentStep === 5}
                                className="btn btn-primary btn-round btn-next-step px-5">Next<i className="mdi mdi-arrow-right ms-2" /></button></>
                        :
                        <>
                            <button onClick={() => submitDone()} type="button"
                                className="btn btn-primary btn-round  me-3 px-5">Done</button>
                        </>
                }


            </div>
        </div>


    );
};

export default StakingTab;
