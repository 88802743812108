import Web3 from "web3";
import abiContract from "../contracts/CrossWallet.json";
import stakingABIContract from "../contracts/staking.json";
import { BigNumber } from "bignumber.js";
import axios from "axios";
import _ from "lodash";
import { STAKING_CONTRACT_ADDRESS, BSC_NODE_URL, MODE } from "../../_configs";
let bscURI = BSC_NODE_URL[MODE][_.random(0, BSC_NODE_URL[MODE].length - 1)];
let web3


//get info from staking contract

export async function getStakingContractInfo() {

    // console.log("BSC_NODE_URL===>", BSC_NODE_URL)
    const bscURI = BSC_NODE_URL[MODE][_.random(0, BSC_NODE_URL[MODE].length - 1)];
    // console.log("provider URI==>", bscURI);
    const web3 = new Web3(bscURI);
    const stakingContract = new web3.eth.Contract(
        stakingABIContract,
        STAKING_CONTRACT_ADDRESS[MODE]
    );

    try {
        const info = await stakingContract.methods.info().call();
        return {
            tokenAddr: info[0],
            tokenSymbol: info[1],
            tokenDecimals: Number(info[2]),
            stakerCount: Number(info[3]),
            totalStakedAmount: BigNumber(info[4])
                .dividedBy(10 ** 18)
                .toString(),
            apyPercentage: Number(info[5]),
            unstakePeriod: Number(info[6]),
            isStakingPaused: info[7],
            totalRewardsDistributed: BigNumber(info[8])
                .dividedBy(10 ** 18)
                .toString(),
        };
    } catch (error) {
        // debugger
        console.log(error);
        return {
            tokenAddr: 0,
            tokenSymbol: 0,
            tokenDecimals: 0,
            stakerCount: 0,
            totalStakedAmount: 0,
            apyPercentage: 0,
            unstakePeriod: 0,
            isStakingPaused: 0,
            totalRewardsDistributed: 0,
        };
    }
}