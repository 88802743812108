import Web3 from "web3";
import window from "global";
import chainId from "../contracts/chainId";
import erc20Abi from "../contracts/erc20.abi";
import abiContract from "../contracts/CrossWallet.json";
import stakingABIContract from "../contracts/staking.json";
import { calculateBalanceSend } from "./utils";
import { BigNumber } from "bignumber.js";
import { MODE, STAKING_CONTRACT_ADDRESS } from "../../_configs";
import { extensionName, STATUS } from "../constants/values";

export default class WalletExtensionUtils {
  constructor(ex) {
    this.web3 = null;
    this.extension = null;

    this.isWrongNetwork = false;
    this.extensionName = ex;
    this.address = "";
    let self = this;
  }

  async connect() {
    let self = this;
    if (self.extensionName === extensionName.binanceExtension) {
      if (window.BinanceChain) {
        self.extension = window.BinanceChain;
        self.web3 = new Web3(window.BinanceChain);
        console.log("CONNECT BLOCKCHAIN_NETWORK==>", MODE);

        try {
          const envCheck =
            MODE === "TESTNET"
              ? !(
                  window.BinanceChain.chainId ===
                  Web3.utils.numberToHex(chainId.bscTestnet)
                )
              : !(
                  window.BinanceChain.chainId ===
                  Web3.utils.numberToHex(chainId.bscMainnet)
                );

          // console.log("window.ethereum.chainId==>", window.ethereum.chainId);
          // console.log("Web3.utils.numberToHex(chainId.bscTestnet)==>", Web3.utils.numberToHex(chainId.bscTestnet));

          if (envCheck) {
            self.isWrongNetwork = true;
            return;
          }

          await window.BinanceChain.enable();
          const addresses = await self.web3.eth.getAccounts();
          self.address = addresses[0];
        } catch (error) {
          console.error(error.message);
          self.web3 = null;
        }
      } else throw new Error("Detect Binance Extension failed!");
    } else if (
      self.extensionName === extensionName.metamask ||
      self.extensionName === extensionName.trustWallet
    ) {
      if (window.ethereum) {
        // console.log("get window.ethereum");
        self.extension = window.ethereum;
        self.web3 = new Web3(window.ethereum);

        // console.log("window.ethereum enable");
        await window.ethereum.enable();

        const envCheck =
          MODE === "TESTNET"
            ? !(
                window.ethereum.chainId ===
                  Web3.utils.numberToHex(chainId.bscTestnet) ||
                window.ethereum.chainId == chainId.bscTestnet ||
                window.ethereum.networkVersion == chainId.bscTestnet ||
                (!window.ethereum.chainId && !window.ethereum.networkVersion)
              )
            : !(
                window.ethereum.chainId ===
                  Web3.utils.numberToHex(chainId.bscMainnet) ||
                window.ethereum.chainId == chainId.bscMainnet ||
                window.ethereum.networkVersion == chainId.bscMainnet ||
                (!window.ethereum.chainId && !window.ethereum.networkVersion)
              );

        if (envCheck) {
          self.isWrongNetwork = true;
          return;
        }

        try {
          const addresses = await self.web3.eth.getAccounts();
          self.address = addresses[0];
        } catch (error) {
          console.error(error.message);
          self.web3 = null;
        }
      } else throw new Error("Detect Wallet failed!");
    }
  }

  accountsChanged(callback) {
    const self = this;
    // console.log(this.extension);

    this.extension.on("accountsChanged", function (accounts) {
      self.address = accounts[0];
      callback(accounts[0]);
    });
  }

  chainChanged(callback) {
    const self = this;
    // debugger;
    this.extension.on("chainChanged", function (chainId) {
      console.log("chainId==>", chainId);
      self.extension = window.ethereum;
      self.web3 = new Web3(window.ethereum);
      callback(chainId);
    });
  }

  isConnected() {
    return this.web3 !== null;
  }
  checkWrongNetwork() {
    return this.isWrongNetwork;
  }

  async approve({ tokenContractAddress, contractAddress, amount }, callback) {
    const self = this;
    // console.log("amount==>", amount);
    amount = calculateBalanceSend(amount);
    try {
      const tokenContract = new self.web3.eth.Contract( erc20Abi, tokenContractAddress);
      callback({
        status: STATUS.APPROVING,
      });
      const amountInHex = "0x" + amount.toString(16);
      
      const gasPrice = await this.web3.eth.getGasPrice();

      await tokenContract.methods
        .approve(contractAddress, amountInHex)
        .send({ from: self.address, gasPrice });
      // }
      callback({
        status: STATUS.APPROVED,
      });
    } catch (error) {
      callback({
        status: STATUS.APPROVE_FAILS,
      });
      console.log(error);
    }
  }

  //get current account
  getCurrentAddress() {
    return this.address;
  }

  async getTokenBalance(tokenAddress) {
    const tokenContract = new this.web3.eth.Contract(erc20Abi, tokenAddress);

    const tokenBalance = await tokenContract.methods
      .balanceOf(this.address)
      .call();
    // return exactMath.div(tokenBalance, exactMath.pow(10, 18))
    return new BigNumber(tokenBalance.toString())
      .dividedBy(10 ** 18)
      .toFixed(18)
      .replace(/\.?0+$/, "")
      .toString();
  }

  async getAllowance(tokenAddress, contractAddress) {
    const tokenContract = new this.web3.eth.Contract(erc20Abi, tokenAddress);

    const allocationNumber = await tokenContract.methods
      .allowance(this.address, contractAddress)
      .call();
    const decimal = await tokenContract.methods.decimals().call();
    // return exactMath.div(allocationNumber, exactMath.pow(10, 18))
    return new BigNumber(allocationNumber.toString())
      .dividedBy(10 ** Number(decimal))
      .toString();
    // return parseFloat(allocationNumber / 10 ** 18);
  }

  async getTokenStakingBalance() {
    const contract = new this.web3.eth.Contract(
      stakingABIContract,
      STAKING_CONTRACT_ADDRESS[MODE]
    );
    const tokenAddress = await contract.methods.token().call();
    const balance = await this.getTokenBalance(tokenAddress);
    return balance;
  }

  async stakingDeposit({ amount }, callback) {
    const self = this;
    const contract = new self.web3.eth.Contract(
      stakingABIContract,
      STAKING_CONTRACT_ADDRESS[MODE]
    );


    amount = calculateBalanceSend(amount);

    const amountInHex = "0x" + amount.toString(16);
    try {
      const gasPrice = await this.web3.eth.getGasPrice();
      const depositResult = await contract.methods.stake(amountInHex).send({ from: self.address,gasPrice })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: STATUS.STAKING_DEPOSIT_FAIL,
          });
        })
        .on("transactionHash", (hash) => {
          callback({
            status: STATUS.STAKING_DEPOSIT_SUBMIT,
            txID: hash,
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: STATUS.STAKING_DEPOSIT_SUCCESS,
              txID: receipt.transactionHash,
            });
          } else callback({ status: STATUS.STAKING_DEPOSIT_FAIL });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: STATUS.STAKING_DEPOSIT_FAIL });
        });
      return depositResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: STATUS.STAKING_DEPOSIT_FAIL,
      });
      return e.message;
    }
  }

  //request withdraw staking
  async stakingInitiateWithdrawal({ amount }, callback) {
    const self = this;
    const contract = new self.web3.eth.Contract(
      stakingABIContract,
      STAKING_CONTRACT_ADDRESS[MODE]
    );
    amount = calculateBalanceSend(amount);
    const amountInHex = "0x" + amount.toString(16);
    
    try {
      const gasPrice = await this.web3.eth.getGasPrice();
      const initiateWithdrawalResult = await contract.methods
        .unstake(amountInHex)
        .send({ from: self.address, gasPrice })
        .on("transactionHash", (hash) => {
          callback({
            status: STATUS.STAKING_INITIATE_WITHDRAWAL_SUBMIT,
            txID: hash,
          });
        })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL,
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: STATUS.STAKING_INITIATE_WITHDRAWAL_SUCCESS,
              txID: receipt.transactionHash,
            });
          } else callback({ status: STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL });
        });
      return initiateWithdrawalResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL,
      });
      return e.message;
    }
  }

  // execute withdraw staking
  async stakingExecuteWithdrawal(callback) {
    const self = this;
    const contract = new self.web3.eth.Contract(
      stakingABIContract,
      STAKING_CONTRACT_ADDRESS[MODE]
    );

    try {
      const gasPrice = await this.web3.eth.getGasPrice();
      const executeWithdrawalResult = await contract.methods
        .withdraw()
        .send({ from: self.address, gasPrice })
        .on("transactionHash", (hash) => {
          callback({
            status: STATUS.STAKING_EXECUTE_WITHDRAWAL_SUBMIT,
            txID: hash,
          });
        })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL,
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: STATUS.STAKING_EXECUTE_WITHDRAWAL_SUCCESS,
              txID: receipt.transactionHash,
            });
          } else callback({ status: STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL });
        });
      return executeWithdrawalResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL,
      });
      return e.message;
    }
  }

  // execute withdraw rewards
  async stakingExecuteWithdrawRewards(callback) {
    const self = this;
    const contract = new self.web3.eth.Contract(
      stakingABIContract,
      STAKING_CONTRACT_ADDRESS[MODE]
    );

    try {
      const gasPrice = await this.web3.eth.getGasPrice();
      const executeWithdrawRewardsResult = await contract.methods
        .withdrawRewards()
        .send({ from: self.address, gasPrice })
        .on("transactionHash", (hash) => {
          callback({
            status: STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_SUBMIT,
            txID: hash,
          });
        })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL,
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS,
              txID: receipt.transactionHash,
            });
          } else
            callback({ status: STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL });
        });
      return executeWithdrawRewardsResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL,
      });
      return e.message;
    }
  }

  async stakingRewards(callback) {
    const self = this;
    const contract = new self.web3.eth.Contract(
      stakingABIContract,
      STAKING_CONTRACT_ADDRESS[MODE]
    );

    try {
      const gasPrice = await this.web3.eth.getGasPrice();

      const executeStakeRewardsResult = await contract.methods
        .stakeRewards()
        .send({ from: self.address , gasPrice})
        .on("transactionHash", (hash) => {
          callback({
            status: STATUS.STAKING_REWARDS_SUBMIT,
            txID: hash,
          });
        })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: STATUS.STAKING_REWARDS_FAIL,
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: STATUS.STAKING_REWARDS_SUCCESS,
              txID: receipt.transactionHash,
            });
          } else callback({ status: STATUS.STAKING_REWARDS_FAIL });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: STATUS.STAKING_REWARDS_FAIL });
        });
      return executeStakeRewardsResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: STATUS.STAKING_REWARDS_FAIL,
      });
      return e.message;
    }
  }

  async getStakingInfoWallet() {
    const contract = new this.web3.eth.Contract(
      stakingABIContract,
      STAKING_CONTRACT_ADDRESS[MODE]
    );

    const infoWallet = await contract.methods.infoWallet(this.address).call();
    // infoWallet[0] = 1000*10**18
    // console.log("rewardAmount==>", BigNumber(infoWallet[5].toString()).dividedBy(10 ** 18).toFixed(18).replace(/\.?0+$/,"").toString());
    return {
      stakedAmount: BigNumber(infoWallet[0].toString())
        .dividedBy(10 ** 18)
        .toString(),
      unstakedAmount: BigNumber(infoWallet[1].toString())
        .dividedBy(10 ** 18)
        .toString(),
      depositTimestamp: Number(infoWallet[2]) * 1000,
      lastUnstakeTimestamp: Number(infoWallet[3]) * 1000,
      withdrawTimestamp: Number(infoWallet[4]) * 1000,
      rewardAmount: BigNumber(infoWallet[5].toString())
        .dividedBy(10 ** 18)
        .toFixed(18)
        .replace(/\.?0+$/, "")
        .toString(),
    };
  }
  //add set kyc
}
