
// console.log("evn",process.env.NODE_ENV);
// if (process.env.NODE_ENV === 'production') {
//     //we are in production-return product key!
//     module.exports = require('./prod-config');
// } else {
//     //we are in dev-return dev key!
//     module.exports = require('./dev-config');
// }

export const  MODE = process.env.REACT_APP_MODE || 'MAINNET';
export const BSC_EXPLORER ={
    MAINNET:'https://bscscan.com',
    TESTNET: ''
}

export const BSC_NODE_URL ={
    MAINNET:[
        "https://bsc-dataseed1.ninicoin.io/",
        "https://bsc-dataseed.binance.org/",
        "https://bsc-dataseed1.defibit.io/",
    ],
    TESTNET: ''
}

export const STAKING_CONTRACT_ADDRESS = {
    MAINNET:'0xe06F0cD562b4FCA0Bdc71EcBa46A8f140cfAa1B0',
    TESTNET: ''
}
export const BSC_CWT_ADDRESS = {
    MAINNET:'0x5A726a26eDB0Df8Fd55f03cc30aF8A7cEa81e78D',
    TESTNET: ''  
}