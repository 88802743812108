import React from 'react';
import { Link } from "react-router-dom";
import packageJson from '../../../package.json';
import ConnectWalletModal from "../modals/ConnectWalletModal";
import HelpModal from "../modals/HelpModal";
import WalletModal from "../modals/WalletModal";
import { ROUTES } from '../constants';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="pp-footer">
        <div className="container-fluid">
          
          <div className="d-flex justify-content-between pp-footer-content">
            <div> Copyright © {currentYear}. All Rights Reserved by CrossWallet</div>
            <div> v{packageJson.version}</div>
            <div className="text-end pp-footer-link">
              <Link target="_blank" to={ROUTES.PRIVACY_POLICY}>Privacy Policy </Link>
              <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms of Use </Link>
            </div>
          </div>
        </div>
      </div>
      <ConnectWalletModal />
      <HelpModal />
      <WalletModal/>
    </>
  );
}