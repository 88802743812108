import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import WithDrawTab from "./WithDrawTab";
import StakingTab from "./StakingTab";
import UnStakingTab from "./UnStakingTab";
import { getStakingContractInfo } from "../../shared/utils/bscHelpers";
import { helpers } from "../../shared/utils";
import RightBar from "./RightBar";
import { ACTION_CONST } from "../../shared/constants";
import Footer from "../../shared/components/Footer";

const StakingPortalPage = (props) => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(1);
    const [stakerCount, setStakerCount] = useState(0);
    const [totalStakedAmount, setTotalStakedAmount] = useState(0);
    const [apyPercentage, setApyPercentage] = useState(0);
    const stakingInfo = useSelector((state) => get(state, "wallet.stakingInfo", {}));
    const submitOK = useSelector((state) =>
        get(state, "utils.blocking", false)
    );

    useEffect(() => {
        try {
            getStakingContractInfo().then(data => {

                setStakerCount(data.stakerCount);
                setTotalStakedAmount(data.totalStakedAmount);
                setApyPercentage(data.apyPercentage);
                dispatch({ type: ACTION_CONST.GET_STAKING_INFO, data: data });
            })
        } catch (error) {
            console.log(error);
        }

        //setup interval
        const job = setInterval(() => {
            getStakingContractInfo().then(data => {

                setStakerCount(data.stakerCount);
                setTotalStakedAmount(data.totalStakedAmount);
                setApyPercentage(data.apyPercentage);
                dispatch({ type: ACTION_CONST.GET_STAKING_INFO, data: data });
            })
        }, 10 * 1000);
       
        dispatch({ type: ACTION_CONST.SET_JOB_GET_STAKING_INFO, job });
    }, [dispatch, submitOK]);

    return (
        <>
            {
                stakingInfo["isStakingPaused"] &&
                <div className="container position-relative text-center d-md-block d-none" style={{ marginTop: -40, zIndex: 9999 }}>
                    <span className="pp-banner-top">
                        <i className="mdi mdi-alert-outline me-2 text-danger">
                        </i> All staking functions are temporarily paused. Please check back again later.
                    </span>
                </div>
            }
            <div className="page-container">
                {
                    stakingInfo["isStakingPaused"] &&
                    <div className="container position-relative mt-4 text-center d-md-none d-block pp-notice">
                        <span className="pp-banner-top">
                            <i className="mdi mdi-alert-outline me-2 text-danger">
                            </i> All staking functions are temporarily paused. Please check back again later.
                        </span>
                    </div>
                }
                <div className="p-content">
                    <div className="container-fluid mt-3 p-scroll">
                        <div className="p-content-tabs">
                            <div className="row align-items-start">
                                <div className="col-xl-4">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation" onClick={() => setSelectedTab(1)}>
                                            <button className="nav-link active" id="stake-tab" data-bs-toggle="tab" data-bs-target="#stake" type="button" role="tab" aria-controls="stake" aria-selected="true">Stake</button>
                                        </li>
                                        <li className="nav-item" role="presentation" onClick={() => setSelectedTab(2)}>
                                            <button className="nav-link" id="unStake-tab" data-bs-toggle="tab" data-bs-target="#unStake" type="button" role="tab" aria-controls="unStake" aria-selected="false">Unstake</button>
                                        </li>
                                        <li className="nav-item" role="presentation" onClick={() => setSelectedTab(3)}>
                                            <button className="nav-link" id="withdraw-tab" data-bs-toggle="tab" data-bs-target="#withdraw" type="button" role="tab" aria-controls="withdraw" aria-selected="false">Withdraw</button>
                                        </li>
                                    </ul>
                                    <div className="mt-3">
                                        {selectedTab == 1 && <h4 className="p-tab-title mb-0 text-nowrap">Stake your CWT</h4>}
                                        {selectedTab == 2 && <h4 className="p-tab-title mb-0 text-nowrap">Unstake your CWT</h4>}
                                        {selectedTab == 3 && <h4 className="p-tab-title mb-0 text-nowrap">Withdraw your CWT</h4>}
                                    </div>
                                </div>
                                <div className="col-xl-8 mt-xl-0 mt-3">
                                    <div className="p-cards-top d-flex justify-content-xl-end justify-content-lg-center justify-content-md-center justify-content-start">
                                        <div className="p-sidebar-card p-card-top mb-0 py-2 px-3 me-md-3 me-2 card-cross">
                                            <div className="p-sidebar-card-title">Number of Stakers</div>
                                            <div className="p-sidebar-card-body mt-2">
                                                <span className="value-staking">{stakerCount}</span>
                                            </div>
                                        </div>
                                        <div className="p-sidebar-card p-card-top mb-0 py-2 px-3 me-md-3 me-2 card-cross">
                                            <div className="p-sidebar-card-title">Total CWT Staked</div>
                                            <div className="p-sidebar-card-body mt-2">
                                                <span className="value-staking">
                                                    {helpers.formatNumberDownRound(totalStakedAmount, 2)}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="p-sidebar-card p-card-top mb-0 py-2 px-3 card-cross">
                                            <div className="p-sidebar-card-title">APY</div>
                                            <div className="p-sidebar-card-body mt-2">
                                                <span className="value-staking">
                                                    {helpers.formatNumberDownRound((apyPercentage / 100).toString(), 2)}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content pt-5 mt-3" id="myTabContent">
                                <StakingTab />
                                <UnStakingTab />
                                <WithDrawTab />
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <RightBar />
            </div>
        </>
    );
};

export default StakingPortalPage;
